import React from 'react';
import styles from './TextWithNumberIcon.module.scss';
import classNames from 'classnames';

export interface TextWithNumberIconProps {
  num: number;
  text: string;
  className?: string;
}

const HeadingWithNumberIcon: React.FC<TextWithNumberIconProps> = ({
  text,
  num,
  className,
}) => {
  return (
    <div className={classNames(className, styles.textWithNumberIcon)}>
      <div className={styles.roundedNumber}>
        <span>{num}</span>
      </div>
      <h2 className={styles.text}>{text}</h2>
    </div>
  );
};

export default HeadingWithNumberIcon;
