import React from 'react';
import styles from './ExploreSection.module.scss';
import HeadingWithNumberIcon from '../../../Composites/TextWithNumberIcon';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import DescriptionBox from '../../../Composites/DescriptionBox';
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import classNames from 'classnames';

const ExploreSection: React.FC = () => {
  return (
    <section className={styles.exploreSection}>
      <div className={classNames(styles.content, styles.leftContent)}>
        <img
          src="/explore.png"
          alt="Motivision Product"
          className={styles.exploreImage}
        />
      </div>
      <div className={classNames(styles.content, styles.rightContent)}>
        <HeadingWithNumberIcon
          className={styles.heading}
          text="Program Creation and Distribution"
          num={2}
        />
        <DescriptionBox
          className={styles.descriptionBox}
          title="Rep Count"
          description="Accurately counts repetitions to help athletes maintain consistency and track their workouts."
          icon={<RepeatOutlinedIcon />}
        />
        <DescriptionBox
          className={styles.descriptionBox}
          title="Eccentric/Concentric Pace"
          description="Monitors the pace of each movement phase, ensuring optimal lifting speed for maximum effectiveness."
          icon={<IndeterminateCheckBoxOutlinedIcon />}
        />
        <DescriptionBox
          className={styles.descriptionBox}
          title="Set Trend Analysis"
          description="Programs can be automatically adjusted based on performance data, ensuring optimal results."
          icon={<BrokenImageOutlinedIcon />}
        />
      </div>
    </section>
  );
};

export default ExploreSection;
