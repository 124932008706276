import React from 'react';
import styles from './TechnologySection.module.scss';
import DescriptionBox from '../../../Composites/DescriptionBox';
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';

const content = [
  {
    icon: <RepeatOutlinedIcon />,
    title: 'Cutting-Edge AI and Machine Learning',
    description:
      'Utilizing state-of-the-art AI and machine learning algorithms, Weight Room analyzes every movement with unparalleled precision.',
  },
  {
    icon: <RepeatOutlinedIcon />,
    title: 'Computer Vision',
    description:
      'Advanced computer vision technology provides detailed metrics on speed, strength, form, and more.',
  },
  {
    icon: <RepeatOutlinedIcon />,
    title: 'High-Frequency Data Capture',
    description:
      'Our system captures millions of data points per second, ensuring comprehensive performance analysis.',
  },
  {
    icon: <RepeatOutlinedIcon />,
    title: 'Seamless Integration',
    description:
      'Effortlessly integrates with existing equipment for a frictionless user experience.',
  },
];

const TechnologySection: React.FC = () => {
  return (
    <section className={styles.technologySection}>
      <h2 className={styles.title}>Our Technology</h2>
      <div className={styles.grid}>
        {content.map((item, index) => (
          <DescriptionBox
            key={index}
            className={styles.descriptionBox}
            title={item.title}
            description={item.description}
            icon={item.icon}
          />
        ))}
      </div>
    </section>
  );
};

export default TechnologySection;
