import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Home from '../screens/home/home';
import { HomeRoute } from './routes';

export interface RouterWrapperProps {}

const pathNotExist = (pathName: string) =>
  !Object.values(HomeRoute).includes(pathName as HomeRoute);

const RouterWrapper: React.FC<RouterWrapperProps> = ({}) => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (pathNotExist(location.pathname)) {
      navigate(HomeRoute.Home);
    }
  }, []);
  return (
    <Routes>
      <Route path={HomeRoute.Home} element={<Home />} />
    </Routes>
  );
};

export default RouterWrapper;
