import React from 'react';
import styles from './DescriptionBox.module.scss';
import classNames from 'classnames';

export interface DescriptionBoxProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  className?: string;
}

const DescriptionBox: React.FC<DescriptionBoxProps> = ({
  icon,
  className,
  title,
  description,
}) => {
  return (
    <div className={classNames(className, styles.descriptionBox)}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.content}>
        <h3 className={styles.title}>{title}</h3>
        <span className={styles.description}>{description}</span>
      </div>
    </div>
  );
};

export default DescriptionBox;
