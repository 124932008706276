import React from 'react';
import RouterWrapper from './router/RouterWrapper';
import { BrowserRouter as Router } from 'react-router-dom';
import styles from './App.module.scss';
import './styleConsts.scss';

const App: React.FC = () => {
  return (
    <div className={styles.app}>
      <Router>
        <RouterWrapper />
      </Router>
    </div>
  );
};

export default App;
