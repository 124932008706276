import React from 'react';
import styles from './home.module.scss';
import HomeHeader from './HomeHeader';
import Footer from '../../components/Footer';
import SectionDivider from '../../Composites/SectionDivider';
import ProductSection from './sections/ProductSection';
import ExploreSection from './sections/ExploreSection';
import InsightsSection from './sections/InsightsSection';
import TechnologySection from './sections/TechnologySection';

const Home: React.FC = () => {
  return (
    <>
      <HomeHeader />
      <main className={styles.main}>
        <ProductSection />
        <SectionDivider type="left" />
        <ExploreSection />
        <SectionDivider type="right" />
        <InsightsSection />
        <TechnologySection />
      </main>
      <Footer />
    </>
  );
};

export default Home;
