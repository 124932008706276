import React from 'react';
import styles from './InsightsSection.module.scss';
import TextWithNumberIcon from '../../../Composites/TextWithNumberIcon';

const InsightsSection: React.FC = () => {
  return (
    <section className={styles.insightsSection}>
      <TextWithNumberIcon text="Insights" num={3} />
      <div className={styles.content}>
        {[
          {
            thumbnail: '/insightsImageLeft.png',
            title: 'Weight Lifting Insights',
            description:
              'Determine if the weight being lifted is appropriate for the individual, ensuring safe and effective training.',
          },
          {
            thumbnail: '/insightsImageRight.png',
            title: 'Strength Progress Tracking',
            description:
              'Monitor strength improvements over time, with detailed analytics showing how much stronger athletes are becoming.',
          },
        ].map(({ thumbnail, title, description }) => (
          <div key={thumbnail} className={styles.insightItem}>
            <img
              src={thumbnail}
              alt="Motivision"
              className={styles.thumbnail}
            />
            <div>
              <h3 className={styles.insightItemTitle}>{title}</h3>
              <h4>{description}</h4>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default InsightsSection;
