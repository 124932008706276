import React from 'react';
import styles from './HeaderContent.module.scss';
import Button from '../atoms/Button';
import LoginIcon from '@mui/icons-material/Login';

const HeaderContent: React.FC = () => {
  const navigateToDashboard = () => {
    window.open('https://dashboard.motivision.us', '_blank');
  };
  return (
    <div className={styles.header}>
      <div className={styles.logo}>
        <img src="/motivision-logo.png" alt="Motivision" />
      </div>
      <Button
        onClick={navigateToDashboard}
        type="info"
        text="Login"
        icon={<LoginIcon />}
      />
    </div>
  );
};

export default HeaderContent;
