import React from 'react';
import styles from './Footer.module.scss';

const socialLinks = [
  {
    src: '/instagramIcon.png',
    link: 'https://www.instagram.com/motivision_us/',
    alt: 'Motivision Instagram',
  },
  {
    src: 'linkedInIcon.png',
    link: 'https://www.linkedin.com/company/motivisiongroup/',
    alt: 'Motivision LinkedIn',
  },
];

const Footer: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.logo}>
        <img
          src="/motivision-logo.png"
          alt="Motivision"
          className={styles.exploreImage}
        />
      </div>
      <div className={styles.socialLinks}>
        {socialLinks.map(({ link, src, alt }) => (
          <a key={link} href={link} target="_blank" rel="noreferrer">
            <img className={styles.socialIcon} src={src} alt={alt} />
          </a>
        ))}
      </div>
      {/*<div className={styles.contact}>*/}
      {/*  <div className={styles.contactContentWrapper}>*/}
      {/*    <h5 className={styles.title}>CONTACT US</h5>*/}
      {/*    <a className={styles.contactContent} href="mailto:motivision@us.com">*/}
      {/*      motivision@us.com*/}
      {/*    </a>*/}
      {/*    <span className={styles.contactDescription}>© 2023 - Copyright</span>*/}
      {/*  </div>*/}
      {/*  <div className={styles.contactContentWrapper}>*/}
      {/*    <h5 className={styles.title}>FIND US</h5>*/}
      {/*    <span className={styles.contactContent}>Address</span>*/}
      {/*    <span className={styles.contactDescription}>*/}
      {/*      Everyday from 10 am to 8 pm*/}
      {/*    </span>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </footer>
  );
};

export default Footer;
