import React from 'react';
import styles from './SectionDivider.module.scss';

export interface SectionDividerProps {
  type: 'left' | 'right';
}

const SectionDivider: React.FC<SectionDividerProps> = ({ type }) => {
  return (
    <section
      className={styles.sectionDivider}
      style={{
        backgroundImage: `url(${
          type === 'left' ? 'dividerLeftToRight.png' : '/dividerRightToLeft.png'
        })`,
      }}
    />
  );
};

export default SectionDivider;
