import React from 'react';
import styles from './Button.module.scss';
import classNames from 'classnames';

export interface ButtonProps {
  text?: string;
  className?: string;
  type?: 'primary' | 'info';
  icon?: React.ReactNode;
  onClick: () => void;
  children?: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({
  text,
  icon,
  onClick,
  className,
  type = 'info',
  children,
}) => {
  return (
    <button
      onClick={onClick}
      className={classNames(className, styles.button, styles[type])}
    >
      <div className={styles.buttonContent}>
        {text}
        {icon}
      </div>
    </button>
  );
};

export default Button;
