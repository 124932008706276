import React from 'react';
import HeaderContent from '../../components/HeaderContent';
import styles from './HomeHeader.module.scss';

const HomeHeader: React.FC = () => {
  return (
    <header className={styles.header}>
      <video autoPlay muted loop className={styles.backgroundVideo}>
        <source
          src="https://video.wixstatic.com/video/e3ba49_b8d1da02efc945a2865247811ee5b8a4/1080p/mp4/file.mp4"
          type="video/mp4"
        />
      </video>
      <div className={styles.headerContent}>
        <HeaderContent />
        <div className={styles.textsWrapper}>
          <h1 className={styles.title}>
            Unlock Peak Performance with AI-Powered Analysis
          </h1>
          <h5>
            Transform your weight room into a smart training environment using
            our advanced cameras that track millions of body movement data
            points per second to deliver real-time performance analysis and
            personalized insights.
          </h5>
        </div>
      </div>
    </header>
  );
};

export default HomeHeader;
