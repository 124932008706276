import React from 'react';
import styles from './ProductSection.module.scss';
import HeadingWithNumberIcon from '../../../Composites/TextWithNumberIcon';

const ProductSection: React.FC = () => {
  return (
    <section className={styles.productSection}>
      <h2 className={styles.title}>Our Product</h2>
      <div className={styles.productDetailsWrapper}>
        <div className={styles.leftContent}>
          <HeadingWithNumberIcon
            text="Program Creation and Distribution"
            num={1}
          />
          <h3 className={styles.subTitle}>Trainer-Designed Workouts</h3>
          <p className={styles.paragraph}>
            Transform your weight room into a smart training environment using
            our advanced cameras that track millions of body movement data
            points per second to deliver real-time performance analysis and
            personalized insights.
          </p>
          <br />
          <span className={styles.bulletDescription}>
            Seamless Distribution
          </span>
          <br />
          <span className={styles.bulletDescription}>
            Performance Based Adjustments
          </span>
        </div>
        <div className={styles.rightContent}>
          <img
            src="/productSectionThumbnail.png"
            alt="Motivision Product"
            className={styles.coverImage}
          />
        </div>
      </div>
    </section>
  );
};

export default ProductSection;
